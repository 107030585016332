@import 'assets/styles/mixins.scss';

.topbar {
  background: transparent;
  min-height: rem(64);
  border-bottom: 1px solid $border;
  padding: rem(12) rem(20);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.info {
  display: flex;
  align-items: flex-end;
  .brand {
    width: 130px;
    height: auto;
    padding: 0.35rem 0;

    @media (max-width: $sm-max-width) {
      margin-left: 30px;
    }
  }
  span[description] {
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: bold;
    color: $black;
  }
}
